var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("BUSCADOR")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Busca usuário"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-select',{staticClass:"ml-2",attrs:{"items":[
              { text: 'Selecionar', value: null },
              { text: 'Aluno', value: 1 },
              { text: 'Professor', value: 2 },
              { text: 'Administrador', value: 3 } ],"label":"Grupo"},model:{value:(_vm.filters.group),callback:function ($$v) {_vm.$set(_vm.filters, "group", $$v)},expression:"filters.group"}}),_c('v-btn',{staticClass:"mx-2 my-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.loadUsers()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{staticClass:"mx-2 my-3",on:{"click":function($event){return _vm.clearFilters()}}},[_vm._v("Limpar")])],1)],1)],1)],1),_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v("Usuários")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"disable-pagination":"","hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","small":"","color":"success"},on:{"click":function($event){return _vm.$router.push('/admin/usuarios/cadastrar')}}},[_vm._v("Cadastrar +")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"title":"Editar dados","icon":""},on:{"click":function($event){return _vm.editUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"title":"Excluir","icon":""},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"mb-2"},[_c('UserRooms',{staticClass:"mt-2",attrs:{"rooms":item.userRooms}})],1)])]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center mt-2"},[_c('v-pagination',{attrs:{"length":_vm.numberOfPages},on:{"input":_vm.loadUsers},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }