<template>
  <div>
    <v-card>
      <v-card-title>BUSCADOR</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="d-flex flex-row">
            <v-text-field
              v-model="filters.search"
              clearable
              placeholder="Busca usuário"
            ></v-text-field>
            <v-select
              class="ml-2"
              v-model="filters.group"
              :items="[
                { text: 'Selecionar', value: null },
                { text: 'Aluno', value: 1 },
                { text: 'Professor', value: 2 },
                { text: 'Administrador', value: 3 },
              ]"
              label="Grupo"
            >
            </v-select>
            <v-btn class="mx-2 my-3" color="primary" @click="loadUsers()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> BUSCADOR </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-flex flex-row">
              <v-text-field
                v-model="filters.search"
                clearable
                placeholder="Busca usuário"
              ></v-text-field>
              <v-select
                class="ml-2"
                v-model="filters.group"
                :items="[
                  { text: 'Selecionar', value: null },
                  { text: 'Aluno', value: 1 },
                  { text: 'Professor', value: 2 },
                  { text: 'Administrador', value: 3 },
                ]"
                label="Grupo"
              >
              </v-select>
              <v-btn class="mx-2 my-3" color="primary" @click="loadUsers()">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn @click="clearFilters()" class="mx-2 my-3">Limpar</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->

    <v-card class="mt-3">
      <v-card-title>Usuários</v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          :loading="loading"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          disable-pagination
          hide-default-footer
          show-expand
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                color="success"
                @click="$router.push('/admin/usuarios/cadastrar')"
                >Cadastrar +</v-btn
              >
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Editar dados"
              @click="editUser(item.id)"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              title="Excluir"
              @click="deleteUser(item.id)"
              icon
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="mb-2">
                <UserRooms class="mt-2" :rooms="item.userRooms" />
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        @input="loadUsers"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import UserRooms from "@/components/Users/UserRooms.vue";

export default {
  components: {
    UserRooms,
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
  },
  data: () => ({
    loading: false,
    singleExpand: true,
    resultsPerPage: 15,
    totalRows: 0,
    page: 1,
    headers: [
      { text: "Nome", value: "fullName" },
      { text: "Tipo", value: "Group.group_name" },
      { text: "Ações", value: "actions" },
      { text: "Salas", value: "data-table-expand" },
    ],
    users: [],
    expanded: [],
    filters: {
      currentPage: 1,
      search: null,
      group: null,
    },
  }),
  methods: {
    async loadUsers(page) {
      try {
        this.loading = true;

        console.log(page);

        let currentPage = page ? page : 1;

        const response = await this.$axios.get(`/admin/usuarios`, {
          params: {
            search: this.filters.search,
            group: this.filters.group,
            pagination: 1,
            resultsPerPage: this.resultsPerPage,
            page: currentPage,
          },
        });

        this.setUsers(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setUsers(data) {
      const cpData = { ...data };

      this.totalRows = cpData.count;

      console.log(cpData);

      this.users = cpData.rows.map((u) => ({
        fullName: `${u.firstName} ${u.lastName}`,
        ...u,
      }));
    },
    async deleteUser(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Alerta de AÇÃO IRREVERSÍVEL",
          `Deseja realmente deletar este usuário do sistema?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/admin/usuarios/${id}/deletar`;

        await this.$axios.delete(url);

        this.deleteUserFromTable(id);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    deleteUserFromTable(id) {
      const index = this.users.findIndex((p) => p.id === id);

      this.users.splice(index, 1);
    },
    editUser(id) {
      this.$router.push(`/admin/usuarios/${id}/editar`);
    },
    clearFilters() {
      this.filters = {
        currentPage: 1,
        search: null,
        group: null,
      };

      this.loadUsers();
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadUsers();
  },
};
</script>

<style></style>
